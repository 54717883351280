import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const OFFER_LOCALES = {
	WOLTAIR_CZ: 'nabidka',
	WOLTAIR_PL: 'zapytanie',
	WOLTAIR_DE: 'angebot',
	WOLTAIR_IT: 'offerta'
}

export const OFFER = OFFER_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return OFFER === param
}) satisfies ParamMatcher
